import moment from 'moment'
import {
	dniLengthErrorMsg,
	dniRegexErrorMsg,
	dobInvalidErrorMsg,
	passportCharacterErrorMsg,
	passportLengthErrorMsg,
	wsCharacterErrorMsg,
	wsLengthErrorMsg,
} from './validationErrorsMsg'

export function nameValidLength(value: string) {
	if (typeof value !== 'string') return
	return value.length >= 2 && value.length <= 50
}

export const dniRegex = /^[0-9]+$/
export function dniValidLength(value: string) {
	if (typeof value !== 'string') {
		return
	}
	return value.length >= 6 && value.length <= 10
}
export const passportRegex = /^[A-Z0-9]+$/
export function passportValidLength(value: string) {
	if (typeof value !== 'string') {
		return
	}
	return value.length >= 8 && value.length <= 10
}

export const wsRegex = /^[0-9]+$/i
export function wsValidLength(value: string) {
	if (typeof value !== 'string') {
		return
	}
	return value.length >= 9 && value.length <= 12
}

export function validateUserAge(dob: string, dependant?: string | boolean) {
	if (typeof dob !== 'string') {
		return
	}
	const edad = moment().year() - moment(dob).year()
	
	// Validar que no haya nacido en el futuro
	if (moment(dob).isAfter(moment())) {
		return 'La fecha de nacimiento no puede ser posterior a hoy'
	}

	// Solo validar mayoría de edad si no es México y no es dependiente
	if (!dependant && process.env.NEXT_PUBLIC_COUNTRY !== 'MX') {
		if (edad < 18) {
			return 'Debe ser mayor de 18 años'
		}
	}
	
	if (edad >= 120) {
		return 'Creemos que es un error que tenga más de 120 años'
	}
}
export function checkInvalidDate(dob: string) {
	if (typeof dob !== 'string') {
		return
	}
	if (moment(dob).isAfter(moment())) {
		return dobInvalidErrorMsg
	}
}

export function checkValidName(value: string | undefined) {
	// Si no hay valor o no es string, es válido (para campos opcionales)
	if (typeof value !== 'string' || !value) {
		return true;
	}

	// Validar longitud
	if (value.length < 2) {
		return "El nombre debe tener al menos 2 caracteres";
	}
	if (value.length > 50) {
		return "El nombre no debe exceder los 50 caracteres";
	}

	// Validar caracteres especiales consecutivos
	if (/[.,'/-]{2,}/.test(value)) {
		return "No se permiten caracteres especiales consecutivos";
	}

	// Validar caracteres permitidos
	if (!/^[A-ZÑa-zñ\s.,'/-]*$/.test(value)) {
		return "El nombre contiene caracteres no permitidos";
	}

	return true;
}

export function checkValidWs(value: string) {
	if (typeof value !== 'string' || !value) {
		return
	}
	if (!wsRegex.test(value)) {
		return wsCharacterErrorMsg
	}
	if (!wsValidLength(value)) {
		return wsLengthErrorMsg
	}
}

export function checkValidDni(value: string, isDNI?: boolean, isPassport?: boolean) {
	if (typeof value !== 'string') {
		return
	}
	if (isPassport && !passportValidLength(value)) {
		return passportLengthErrorMsg
	}
	if (isPassport && !passportRegex.test(value)) {
		return passportCharacterErrorMsg
	}
	if (isDNI && !dniValidLength(value)) {
		return dniLengthErrorMsg
	}
	if (isDNI && !dniRegex.test(value)) {
		return dniRegexErrorMsg
	}
}

export function checkValidCompoundLastName(value: string | undefined) {
	if (typeof value !== 'string' || !value) {
		return true;
	}

	// Validar longitud para apellido compuesto (mínimo 4 caracteres)
	if (value.length < 4) {
		return "El apellido completo debe tener al menos 4 caracteres";
	}

	// Validar longitud máxima
	if (value.length > 80) {
		return "El apellido completo no debe exceder los 80 caracteres";
	}

	// Validar que no haya caracteres especiales consecutivos
	if (/[.,'/-]{2,}/.test(value)) {
		return "No se permiten caracteres especiales consecutivos";
	}

	// Validar caracteres permitidos
	if (!/^[A-ZÑa-zñ\s.,'/-]*$/.test(value)) {
		return "El apellido contiene caracteres no permitidos";
	}

	return true;
}
